import React from "react";
import SeoTags from "../components/seo";
import PrivacyPolicyEnglish from "../components/privacyPolicyEnglish";
import PrivacyPolicySerbian from "../components/privacyPolicySerbian";
import Header from "../components/header";
function PrivacyPolicy(props) {
  const { lang } = props;
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return (
    <>
      <SeoTags
        title="Politika privatnosti"
        description="Pogledajte Politiku privatnosti Pomoravskog vodiča."
        path="politika-privatnosti/"
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo="/"
              firstLine={process.env.REACT_APP_CITY.translate()}
              secondLine={"Politika privatnosti".translate()}
              icon={
                <i className="bi bi-file-earmark-text me-2 icon-primary"></i>
              }
            />
          </div>
        </div>
        <div className="container-md my-5">
          <div className="row">
            <div className="m-auto col-lg-8 policy-page">
              {lang === "sr" && <PrivacyPolicySerbian />}
              {lang === "en" && <PrivacyPolicyEnglish />}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default PrivacyPolicy;
