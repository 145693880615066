import React from "react";

function PromoApp(props) {
  const { sidebar } = props;
  return (
    <div
      className={
        sidebar
          ? "container-fluid search-promo-bg-sidebar p-3"
          : "container-fluid search-promo-bg p-3"
      }
    >
      <div className="container-md">
        <div
          className={
            sidebar ? "row search-promo-wrap-sidebar" : "row search-promo-wrap"
          }
        >
          <div className="col-5 search-promo-left">
            <img
              src={process.env.REACT_APP_STATIC_RESOURCES + "phones.png"}
              // height="50"
              alt={"Android aplikacija".translate()}
            />
          </div>
          <div
            className={
              sidebar
                ? "col search-promo-right-sidebar"
                : "col search-promo-right"
            }
          >
            <h2>{"Android aplikacija".translate()}</h2>
            <p>
              {"Preuzmite".translate() +
                " " +
                process.env.REACT_APP_NAME.translate() +
                " " +
                "aplikaciju za vaš Android mobilni uređaj.".translate()}
            </p>
            <div
              className={
                sidebar ? "search-promo-apps-sidebar" : "search-promo-apps"
              }
            >
              <a href={process.env.REACT_APP_ANDROID_LINK}>
                <img
                  src={
                    process.env.REACT_APP_STATIC_RESOURCES + "play-store.svg"
                  }
                  height="50"
                  alt={"Preuzmi aplikaciju na Google Play".translate()}
                  title={"Preuzmi aplikaciju na Google Play".translate()}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoApp;
