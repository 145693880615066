import React, { useState } from "react";
import SubHeader from "../components/subHeader";
import PromoApp from "../components/promoApp";
import SeoTags from "../components/seo";

function Contact(props) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SeoTags />
      <main className="container-fluid p-0">
        <div className="container-md mt-5 mb-5">
          <div className="row">
            <div className="col-md">
              <SubHeader
                title={"Kontakt".translate()}
                loading={loading}
                icon={<i className="bi bi-geo-alt icon-primary"></i>}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row mt-4">
                <div className="col-md">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_RESOURCES +
                          "just_logo.svg"
                        }
                      />
                    </div>
                    <div className="col-md-9 company-title">
                      <div>
                        <h3>{process.env.REACT_APP_NAME.translate()}</h3>
                      </div>
                      <div>{process.env.REACT_APP_PREFIX.translate()}</div>
                      <div>
                        {"Edukativno Poslovni Centar, Jagodina".translate()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="title mb-1 mt-3">{"Telefon".translate()}</div>
              <div>+381 35 8101 047</div>
              <div className="title mb-1 mt-3">
                {"Mobilni telefon".translate()}
              </div>
              <div>+381 63 8730 292</div>
              <div>+381 63 7052 004</div>
              <div className="title mb-1 mt-3">{"E-mail".translate()}</div>
              <div>info@pomoravskivodic.rs</div>
              <div>epcjagodina@gmail.com</div>
            </div>
          </div>
        </div>
        <PromoApp />
      </main>
    </>
  );
}

export default Contact;
