import React, { useState, useEffect } from "react";
import Item from "../components/objects/item";
import Header from "../components/header";
import Buttons from "../components/objects/buttons";
import { useParams } from "react-router-dom";
import SidebarCategories from "../components/objects/sidebarCategories";
import PromoApp from "../components/promoApp";
import SeoTags from "../components/seo";
import NotFound from "./notFound";

function Objects(props) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMeta, setLoadingMeta] = useState(true);
  const [distanceState, setDistanceState] = useState(null);
  const [nameState, setNameState] = useState(null);
  const [ratingState, setRatingState] = useState(null);
  const [error, setError] = useState(null);
  const { group } = useParams();
  const { subgroup } = useParams();
  const { lang } = props;

  const handleSortByDistance = () => {
    setDistanceState("active");
    setNameState(null);
    setRatingState(null);
    let sortedObjects = [];
    const myData = []
      .concat(data)
      .sort((a, b) => (a.distance > b.distance ? 1 : -1))
      .map((item, i) => {
        sortedObjects[i] = item;
      });
    setData(sortedObjects);
  };

  const handleSortByName = () => {
    if (distanceState !== "disabled") {
      setDistanceState(null);
    }
    setNameState("active");
    setRatingState(null);
    let sortedObjects = [];
    const myData = []
      .concat(data)
      .sort((a, b) => (a["name_" + lang] > b["name_" + lang] ? 1 : -1))
      .map((item, i) => {
        sortedObjects[i] = item;
      });
    setData(sortedObjects);
  };

  const handleSortByRating = () => {
    if (distanceState !== "disabled") {
      setDistanceState(null);
    }
    setNameState(null);
    setRatingState("active");
    let sortedObjects = [];
    const myData = []
      .concat(data)
      .sort((a, b) => (a.rating < b.rating ? 1 : -1))
      .map((item, i) => {
        sortedObjects[i] = item;
      });
    setData(sortedObjects);
  };

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        stub: subgroup,
        origin: "web",
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_objects.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((objects) => {
        setMeta(objects.meta);
        setLoadingMeta(false);
        navigator.geolocation.getCurrentPosition(
          function (position) {
            let lat1 = position.coords.latitude;
            let lon1 = position.coords.longitude;
            let lat2;
            let lon2;
            let newObjects = [];
            let R = 6371; // km
            const toRadian = (n) => (n * Math.PI) / 180;
            let index;
            objects.objects.map((object) => {
              index = objects.objects.indexOf(object);
              lat2 = object.lat;
              lon2 = object.lng;

              let x1 = lat2 - lat1;
              let dLat = toRadian(x1);
              let x2 = lon2 - lon1;
              let dLon = toRadian(x2);
              let a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadian(lat1)) *
                  Math.cos(toRadian(lat2)) *
                  Math.sin(dLon / 2) *
                  Math.sin(dLon / 2);
              let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              let d = Math.round((R * c + Number.EPSILON) * 100) / 100;
              let newObject = object;
              newObject.distance = d;
              newObjects[index] = newObject;
            });
            if (nameState === null && ratingState === null) {
              let sortedObjects = [];
              const myData = []
                .concat(newObjects)
                .sort((a, b) => (a.distance > b.distance ? 1 : -1))
                .map((item, i) => {
                  sortedObjects[i] = item;
                });

              setData(sortedObjects);
              setDistanceState("active");
              setLoading(false);
              setError(null);
            } else {
              if (nameState === "active") {
                let sortedObjects = [];
                const myData = []
                  .concat(newObjects)
                  .sort((a, b) =>
                    a["name_" + lang] > b["name_" + lang] ? 1 : -1
                  )
                  .map((item, i) => {
                    sortedObjects[i] = item;
                  });
                setData(sortedObjects);
                setDistanceState(null);
                setLoading(false);
                setError(null);
              }

              if (ratingState === "active") {
                let sortedObjects = [];
                const myData = []
                  .concat(newObjects)
                  .sort((a, b) => (a.rating < b.rating ? 1 : -1))
                  .map((item, i) => {
                    sortedObjects[i] = item;
                  });
                setData(sortedObjects);
                setDistanceState(null);
                setLoading(false);
                setError(null);
              }
            }
          },
          function (error) {
            setDistanceState("disabled");
            if (nameState === null && ratingState === null) {
              let sortedObjects = [];
              const myData = []
                .concat(objects.objects)
                .sort((a, b) => (a.rating < b.rating ? 1 : -1))
                .map((item, i) => {
                  sortedObjects[i] = item;
                });
              setData(sortedObjects);
              setRatingState("active");
              setLoading(false);
              setError(null);
            } else {
              if (nameState === "active") {
                let sortedObjects = [];
                const myData = []
                  .concat(objects.objects)
                  .sort((a, b) =>
                    a["name_" + lang] > b["name_" + lang] ? 1 : -1
                  )
                  .map((item, i) => {
                    sortedObjects[i] = item;
                  });
                setData(sortedObjects);
                setLoading(false);
                setError(null);
              }

              if (ratingState === "active") {
                let sortedObjects = [];
                const myData = []
                  .concat(objects.objects)
                  .sort((a, b) => (a.rating < b.rating ? 1 : -1))
                  .map((item, i) => {
                    sortedObjects[i] = item;
                  });
                setData(sortedObjects);
                setLoading(false);
                setError(null);
              }
            }
          }
        );
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [subgroup]);

  return (
    <>
      {meta.response_code === "404" ? (
        <NotFound />
      ) : (
        <>
          <SeoTags
            title={
              !loading &&
              meta.group_name_sr + " / " + meta.subgroup_name_sr + " - Lokacije"
            }
            description={
              "Pogledajte najatraktivnije lokacije u Pomoravlju iz kategorije " +
              (!loading && meta.group_name_sr + " / " + meta.subgroup_name_sr)
            }
            path={"lokacije/" + group + "/" + subgroup + "/"}
          />
          <main className="container-fluid p-0">
            <div className="container-fluid p-0 header-wrap">
              <div className="container-md">
                <Header
                  backTo="/lokacije"
                  firstLine={!loading && meta["group_name_" + lang]}
                  secondLine={!loading && meta["subgroup_name_" + lang]}
                  loading={loading}
                  icon={<i className="bi bi-geo-alt me-2 icon-primary"></i>}
                />
              </div>
            </div>
            <div className="container-md">
              <div className="row">
                <div className="col-lg-8">
                  <Buttons
                    onSortByDistance={handleSortByDistance}
                    distanceState={distanceState}
                    onSortByName={handleSortByName}
                    nameState={nameState}
                    onSortByRating={handleSortByRating}
                    ratingState={ratingState}
                    loading={loading}
                  />
                  <div className="row g-0 px-2 pt-1 pb-3">
                    {loading && (
                      <>
                        <Item loading={loading} />
                        <Item loading={loading} />
                        <Item loading={loading} />
                        <Item loading={loading} />
                        <Item loading={loading} />
                        <Item loading={loading} />
                        <Item loading={loading} />
                        <Item loading={loading} />
                      </>
                    )}
                    {!loading &&
                      data.map((object) => (
                        <Item
                          key={object.location_id}
                          object={object}
                          lang={lang}
                          loading={loading}
                        />
                      ))}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sticky-column mt-3 pb-3">
                    <SidebarCategories
                      lang={lang}
                      group={group}
                      subgroup={subgroup}
                    />
                    <PromoApp sidebar={true} />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default Objects;
